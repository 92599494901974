import React, { FC } from "react";
import Layout from "../components/layout";

const JobsPage: FC = () => (
  <Layout pageTitle={"home"}>
    <p>Jobs</p>
  </Layout>
);

export default JobsPage;
